.user-page-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem 0rem 2rem;
}

.user-details-card {
  margin: 0 auto;
  color: var(--title-color);
  width: 100%;
  text-align: center;
}

.user-details-card .avatar {
  width: 4rem;
  height: 4rem;
}

.user-details {
  padding: 2rem;
  text-align: left;
}

.user-details-card p {
  font-size: 1.2rem;
}

.user-details-title {
  font-size: 2rem;
  font-weight: 500;
}

@media screen and (max-width: 580px) {
  .liked-videos-body {
    padding: 1.5rem 0.5rem 2rem;
  }
}
