.playlist-modal-container {
  background-color: var(--bg-color);
  color: var(--title-color);
  padding: 0 1rem 10px;
  width: 20rem;
}

.playlist-modal-container p {
  color: var(--sub-title-color);
  font-weight: 400;
  font-size: 1rem;
}

.playlist-modal-container > button {
  margin: 10px;
}

.playlist-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem 10px 1rem;
}

.playlist-modal-header button {
  margin: 0;
  margin-right: -10px;
}

.available-playlists {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  border-bottom: 1px solid var(--sub-title-color);
  border-top: 1px solid var(--sub-title-color);
  width: 90%;
  margin: 0 auto 1rem;
}

.available-playlists label {
  display: flex;
  margin: 5px 0;
  cursor: pointer;
}

.available-playlists input {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 1rem;
}

.create-new-playlist-section {
  padding: 1rem;
}

.create-new-playlist-bottom-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
}

.create-new-playlist-bottom-section button {
  margin: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.no-outline-text-input {
  margin: 0;
  width: 100%;
}

.create-new-playlist-section input {
  color: var(--title-color);
}

.create-new-playlist-section textarea {
  background-color: transparent;
  resize: none;
  color: var(--title-color);
}

.create-new-playlist-section input:focus {
  border-bottom: 1px solid var(--primary-color);
}
