.theme-toggler {
  cursor: pointer;
}

.theme-toggler button {
  margin: 0;
  margin-left: 6px;
}

.theme-toggler button i {
  color: var(--title-color);
}

.theme-toggler p {
  display: none;
  padding-right: 1.2rem;
}

.theme-toggler:hover p {
  display: flex;
}
