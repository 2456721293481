:root {
  --bg-color: #fdfdfd;
  --secondary-bg-color: #e8e8e8;
  --card-bg-color: #bebebe91;
  --card-hover-bg-color: rgba(88, 88, 88, 0.162);
  --title-color: #1a1a1a;
  --sub-title-color: #303030;
  --icon-color: rgb(47, 47, 47);
  --card-overlay: rgba(27, 27, 27, 0.686);
}

html[data-pebbleplay-theme="light"] {
  --bg-color: #ffffff;
  --secondary-bg-color: #f0f0f0;
  --card-bg-color: #bebebe91;
  --card-hover-bg-color: rgba(88, 88, 88, 0.162);
  --title-color: #252525;
  --sub-title-color: #303030;
  --icon-color: rgb(118, 118, 118);
  --theme-toggler: rgb(41, 41, 41);
}

html[data-pebbleplay-theme="dark"] {
  --bg-color: #141517;
  --secondary-bg-color: #18191a;
  --card-bg-color: #1a1b1c;
  --card-hover-bg-color: rgba(40, 40, 40, 0.666);
  --title-color: #ebebeb;
  --sub-title-color: #9b9b9b;
  --icon-color: rgb(146, 146, 146);
  --theme-toggler: rgb(220, 220, 220);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  width: 1rem;
  height: 1rem;
}

*::-webkit-scrollbar-track {
  background-color: var(--secondary-bg-color);
  width: 1rem;
}
