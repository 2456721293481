.video-listing-body {
  width: 100%;
  min-height: 90vh;
  margin: 0 auto;
  padding: 2rem 1rem 0rem 2rem;
  margin-left: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.all-videos-section {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.all-videos-section > div {
  margin: 10px;
}

.category-section {
  display: flex;
  align-items: center;
  position: sticky;
  flex-wrap: wrap;
}

.category-label {
  padding: 10px 1rem;
  border: 1px solid var(--primary-color);
  background-color: var(--bg-color);
  color: var(--title-color);
  margin: 6px;
  border-radius: 20px;
  font-size: 0.9rem;
}

.category-label:hover {
  color: var(--title-color);
  cursor: pointer;
}

.category-label:active {
  background-color: var(--primary-color);
}

.active-label {
  background-color: var(--primary-color);
  color: var(--text-white);
}

.no-video-message {
  font-size: 1.2rem;
  color: var(--title-color);
  text-align: center;
  margin-top: 2rem;
}

.result-message {
  font-size: 1.2rem;
  color: var(--title-color);
  margin: 10px;
}

@media screen and (max-width: 768px) {
  .all-videos-section > div {
    margin: 5px;
  }
}

@media screen and (max-width: 580px) {
  .video-listing-body {
    margin: 0 auto;
    padding: 1rem 5px 1rem;
    background-color: var(--secondary-bg-color);
  }

  .all-videos-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 1rem 0;
  }

  .category-section {
    flex-wrap: wrap;
    justify-content: center;
  }

  .category-label {
    padding: 6px 8px;
    margin: 3px 5px;
    border-radius: 10px;
    font-size: 0.9rem;
  }
}
