.camera-animation,
.loader-animation {
  background-color: rgba(0, 0, 0, 0.087);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 5;
}

.camera-animation {
  background-color: var(--bg-color);
}

.loader-animation > div {
  width: 40rem;
  margin: auto;
  top: 50%;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
}

#camera-dark {
  width: 20rem;
  animation: loader 2500ms ease-in-out;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

@keyframes loader {
  0% {
    opacity: 0.4;
  }
  10% {
    opacity: 0.7;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
