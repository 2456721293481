.playlists-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 1.5rem 1rem 0rem 2rem;
  margin-left: 3rem;
}

.playlists-section {
  margin-top: 1rem;
}

.playlists-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: var(--title-color);
  height: auto;
  border-radius: 4px;
}

.playlists-item {
  margin: 10px;
  width: 18rem;
}

.playlists-section h1 {
  font-weight: 500;
}

.playlists-section p {
  color: var(--sub-title-color);
}

.playlists-page-title {
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--title-color);
}

.playlists-page-title button {
  margin: 0 auto 0 2rem;
}

.playlists-page-title i {
  color: var(--primary-color);
}

.playlists-page-sub-title {
  font-size: 1.2rem;
  color: var(--sub-title-color);
  font-weight: 400;
  text-align: center;
  margin-top: 4rem;
}

@media screen and (max-width: 580px) {
  .playlists-body {
    padding: 1.5rem 0.5rem 2rem;
    margin-left: 0;
  }
}
