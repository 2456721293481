.category-card {
  border-radius: 10px;
  width: 9rem;
  height: 7.8rem;
  padding: 0;
  margin: 0 10px;
  margin-bottom: 10px;
  background-color: var(--card-hover-bg-color);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.category-card:hover {
  background-color: var(--card-overlay);
  color: var(--text-white);
}

.category-text-section {
  text-align: center;
}

.category-card i,
.category-card h1 {
  font-size: 1.1rem;
  color: var(--title-color);
  margin: 6px 0;
}

@media screen and (max-width: 1024px) {
  .category-card {
    width: 8rem;
    height: 7rem;
  }

  .category-card i,
  .category-card h1 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .category-card {
    width: 7.5rem;
    height: 7.5rem;
  }

  .category-card i,
  .category-card h1 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 580px) {
  .category-card {
    border-radius: 20px;
    width: 7rem;
    height: 6.5rem;
    margin: 5px;
  }

  .category-card i,
  .category-card h1 {
    font-size: 0.9rem;
  }
}
