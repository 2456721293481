.footer-body {
  width: 90%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin: 2rem auto 1rem;
}

.footer-body h3 {
  font-weight: 400;
  font-size: 0.8rem;
  color: var(--sub-title-color);
  margin: 0 10px;
}

.footer-body a {
  color: var(--title-color);
  margin-left: 5px;
  font-weight: 500;
}

@media screen and (max-width: 400px) {
  .footer-body {
    justify-content: center;
  }

  .github-icon {
    display: none;
  }
}
