.video-card-vertical {
  padding: 0 0 1rem;
  height: 18rem;
  width: 15rem;
  background-color: var(--bg-color);
  border: 1px solid var(--card-hover-bg-color);
}

.video-card-vertical .card-img-container {
  margin: 0 0 4px;
  padding: 0;
  position: relative;
}

.video-card-vertical .card-img-container .trash-icon {
  position: absolute;
  padding: 0.8rem;
  top: 0;
  right: 0;
  border-radius: 4px;
  color: var(--text-white);
  background-color: var(--card-hover-bg-color);
  font-size: 0.8rem;
  cursor: pointer;
}

.video-card-vertical .card-img-container img {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 4px 4px 0 0;
}

.history-delete-btn {
  position: absolute;
  padding: 12px;
  border-radius: 4px;
  margin: 0;
  color: var(--title-color);
  right: 2px;
  top: 2px;
}

.video-card-vertical .card-title {
  font-size: 0.9rem;
  height: 2.2rem;
  overflow: hidden;
  color: var(--title-color);
  margin-bottom: 8px;
}

.video-card-vertical .card-nav-icon {
  display: flex;
  align-items: center;
  width: 93%;
  margin: auto;
  justify-content: space-between;
}

.video-card-vertical .card-nav-icon > div {
  display: flex;
  position: relative;
}

.tool-tip-like,
.tool-tip-watchlater,
.tool-tip-playlist,
.tool-tip-history,
.tool-tip-playlist {
  position: absolute;
  background-color: var(--bg-color);
  font-size: 0.8rem;
  color: var(--title-color);
  padding: 5px;
  border-radius: 4px;
  width: auto;
  z-index: 2;
  top: -2rem;
  visibility: hidden;
  border: 1px solid var(--primary-color);
}

.tool-tip-history {
  right: 1rem;
  top: -0.8rem;
}

.tool-tip-playlist {
  right: 0;
  top: -2rem;
}

.tool-tip:hover .tool-tip-like,
.tool-tip:hover .tool-tip-watchlater,
.tool-tip:hover .tool-tip-playlist,
.tool-tip:hover .tool-tip-history,
.tool-tip:hover .tool-tip-playlist {
  visibility: visible;
}

.video-card-vertical .card-nav-icon button {
  margin: 0 5px;
}

.video-card-vertical .card-nav-icon h2 {
  color: var(--title-color);
}

.video-card-vertical p {
  color: var(--title-color);
}

.video-card-horizontal {
  width: 100%;
  max-width: 55rem;
  background-color: var(--bg-color);
}

.video-card-horizontal .card-body {
  width: 100%;
}

.video-card-horizontal .card-title {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 8px;
}

.video-card-horizontal .card-img-container,
.video-card-horizontal .card-img-container img {
  width: 15rem;
  height: 100%;
  margin: 0;
}

.video-card-horizontal .card-text {
  width: 100%;
  color: var(--title-color);
}

.video-card-horizontal .card-nav {
  width: 100%;
}

.video-card-horizontal .card-nav button {
  font-size: 0.8rem;
  margin: 0;
  margin-left: 10px;
}

.video-author {
  font-size: 0.8rem;
  font-weight: 400;
}

.video-views-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.video-duration {
  font-weight: 500;
}

.video-views-date p {
  font-size: 0.8rem;
  font-weight: 400;
}

.card-text h2 {
  margin-bottom: 5px;
  color: var(--title-color);
}

.animate-card:hover {
  transition: 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: scale(1.05);
  z-index: 1;
}

.icon-inactive {
  color: var(--icon-color);
}

@media screen and (max-width: 768px) {
  .video-card-vertical {
    height: 16rem;
    width: 13.2rem;
  }
}

@media screen and (max-width: 580px) {
  .video-card-vertical {
    height: 17rem;
    width: 15rem;
  }
}
