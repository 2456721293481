.playlist-card-container {
  width: 100%;
  height: 9rem;
  object-fit: contain;
  margin: 0;
  position: relative;
}

.playlist-card-container i {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--title-color);
  padding: 0.8rem;
  margin: 0;
}

.playlist-card-detail {
  background-color: var(--card-hover-bg-color);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--title-color);
  border-radius: 5px;
  font-size: 1.3rem;
}
