.banner-card {
  padding: 0;
  height: auto;
  width: 100%;
  border: none;
  margin: auto;
}

.video-container {
  position: relative;
  width: 100%;
}

video {
  width: 100%;
  margin-bottom: 1.2rem;
  aspect-ratio: 6/2;
  object-fit: cover;
  pointer-events: none;
}

.volume-icon {
  position: absolute;
  top: 5.5rem;
  right: 1.6rem;
  border-radius: 50%;
  color: var(--text-lighter);
}

@media screen and (max-width: 768px) {
  .volume-icon {
    top: 2rem;
    right: 1rem;
  }
}

@media screen and (max-width: 580px) {
  video {
    aspect-ratio: 5/2;
  }

  .volume-icon {
    top: 1rem;
    right: 1rem;
  }
}
