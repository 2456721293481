.landing-body {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 1rem;
  margin: 0 auto;
  margin-left: 3rem;
  width: 100%;
}

.landing-header-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: -5rem;
}

.landing-banner-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-banner-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 0;
  color: var(--title-color);
  padding: 1rem 2.5rem 2.5rem;
}

.landing-banner-text h1 {
  font-size: 2vw;
  margin-bottom: 1rem;
}

.landing-banner-text p {
  font-size: 1.2vw;
}

.landing-category-section {
  margin: 0 1rem 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.landing-title {
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--title-color);
  margin-bottom: 0.7rem;
}

.landing-title i {
  color: var(--primary-color);
}

.landing-sub-title {
  font-size: 1.2rem;
  color: var(--sub-title-color);
  font-weight: 400;
  text-align: center;
  margin-top: 4rem;
}

.landing-must-watch-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.video-title {
  text-align: center;
}

.landing-must-watch-videos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.landing-must-watch-videos > div {
  margin-left: 0;
}

@media screen and (max-width: 1024px) {
  .video-title {
    text-align: left;
  }

  .landing-must-watch-videos {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .landing-body {
    width: 100%;
    padding: 1.5rem 0.5rem 1rem;
    flex-direction: column;
  }

  .landing-header-section {
    margin-top: -2.5rem;
  }

  .landing-banner-section {
    width: 100%;
    margin: 0 0 1.5rem;
  }

  .landing-category-section {
    width: 100%;
    margin: 0 0 1rem;
    padding: 1rem 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .landing-must-watch-container > h1 {
    text-align: center;
  }

  .landing-must-watch-videos {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .landing-banner-text {
    color: var(--title-color);
    padding: 1rem 1rem 2.5rem;
  }

  .landing-banner-text h1 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  .landing-banner-text p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 580px) {
  .landing-body {
    margin: 0 auto;
  }

  .landing-must-watch-videos {
    flex-direction: column;
  }

  .landing-header-section {
    margin-top: -1rem;
  }

  .landing-banner-text {
    color: var(--title-color);
    padding: 1rem 0.6rem 2.5rem;
  }

  .landing-banner-text h1 {
    font-size: 1.2rem;
  }

  .landing-banner-text p {
    font-size: 1rem;
  }
}
