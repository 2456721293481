.vertical-nav-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 4rem;
  width: auto;
  height: 100%;
  padding: 0 0 1rem 0.3rem;
  z-index: 2;
  background-color: var(--bg-color);
}

.nav-bar-mobile {
  display: none;
}

@media screen and (max-width: 580px) {
  .vertical-nav-container {
    position: fixed;
    z-index: 1;
    top: 0;
    width: 18rem;
    height: 100%;
    background-color: var(--bg-color);
    justify-content: flex-start;
  }

  .nav-bar-desktop {
    display: none;
  }

  .nav-bar-mobile {
    display: flex;
    z-index: 4;
  }
}
