.App {
  background-color: var(--bg-color);
}

.app {
  animation: app 200ms ease-in-out;
}

@keyframes app {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.8;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.toast-container {
  margin-top: 3rem;
}

.desktop-navigation-bar {
  display: flex;
}

.mobile-navigation-bar {
  display: none;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.447);
  width: 100%;
  height: 120vh;
  position: fixed;
  z-index: 3;
  margin-top: -4rem;
}

@media screen and (max-width: 580px) {
  .desktop-navigation-bar {
    display: none;
  }

  .mobile-navigation-bar {
    display: flex;
  }

  .not-logged-in {
    flex-direction: column;
  }

  .not-logged-in p {
    margin-bottom: 1rem;
  }
}
